import React, { Component } from 'react';
import { Input } from 'antd';

class SmartInputNumber extends Component {

  onChange = (e) => {
    const { value } = e.target;
    this.props.onChange(value);
  }

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    if (value === '-') {
      onChange({ value: value.slice(0, -1) });
    }
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    //console.log("render");
    return (
      <Input
        style={{textAlign: 'right'}}
        {...this.props}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    );
  }
}

export default SmartInputNumber;
