import React from "react";
import { Link } from "react-router-dom";
import { Steps, Divider, Collapse, Icon, Button, Card, Row } from "antd";
import QueueAnim from "rc-queue-anim";

const { Panel } = Collapse;
const { Step } = Steps;
const graphQlHost = process.env.REACT_APP_INIPROABS_GRAPHQL_HOST;

const Home = () => {
  return (
    <Card
      title="Willkommen beim INIPRO.ABS - JSON Web Token Service"
      bordered={false}
      style={{ width: 700 }}
      extra={<Icon type="cloud-server" style={{ fontSize: 24 }} />}
    >
      <Row gutter={16}>
        <QueueAnim delay={400}>
          <div key="1">
            <Card>
              <QueueAnim duration={600}>
                <div key="1">
                  Ein JSON Web Token ist ein auf JSON basiertes und nach RFC
                  7519 genormtes Access-Token. Es wird typischerweise verwendet,
                  um in einem System mit einem Drittanbieter, die Identität
                  eines Benutzers zwischen einem Identity-Provider und einem
                  Service-Provider auszutauschen.
                </div>
                <div key="2" style={{ marginTop: 10 }}>
                  <Link to="/sst">
                    <Button type="primary">Token anfordern...</Button>
                  </Link>
                </div>
              </QueueAnim>
            </Card>
          </div>
          <div key="2" style={{ marginTop: 12 }}>
            <Collapse>
              <Panel header="Hilfe zur API ..." key="1">
                <p>
                  Der WebService erfordert eine Benutzeranmeldung und steht nur
                  in der DMZ zur Verfügung. Weitere Informationen zum Aufruf des
                  Token-Service gibt es in der ...
                </p>
                <p>
                  <Link to="/sysinfo">
                    <Button type="primary">Experten-Hilfe</Button>
                  </Link>
                </p>
              </Panel>
              <Panel header="Netzwerkprobleme mit 'Failed to Fetch' ..." key="2">
                <p>
                  Im Falle von Zertifikatsproblemen beim Webserver von
                  INIPRO.ABS kann es bereits beim Login zu Problemen
                  kommen, weil ihr Browser nicht verifizierte Zertifikate
                  bei verschlüsselten Verbindungen ablehnt!
                </p>
                <Divider />
                <Steps size="small" direction="vertical">
                  <Step
                    title={
                      <div>
                        <span>Öffnen Sie den </span>
                        <a
                          href={graphQlHost}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          INIPRO.ABS GraphQl-Server
                        </a>
                        <span> direkt.</span>
                      </div>
                    }
                    description="Die Seite wird in einem neuen Tab geöffnet."
                    status='process'
                  />
                  <Step
                    title="Meldung: 'Nicht sicherer Inhalt' oder 'Zertifikatsfehler' beheben"
                    description="Abhängig vom Browser gibt es hier verschiedene Vorgehensweisen, um die Seite als Ausnahme öffnen zu können."
                    status='process'
                  />
                  <Step
                    title="Tab wieder schließen und zurück zu dieser Seite"
                    description="Die hinzugefügte Ausnahme wird ggf. vom Browser nur für eine gewisse Zeit gespeichert!"
                    status='process'
                  />
                </Steps>
              </Panel>
            </Collapse>
          </div>
        </QueueAnim>
      </Row>
    </Card>
  );
};

export default Home;
