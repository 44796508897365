const apiUrl = process.env.REACT_APP_INIPROABS_GRAPHQL_API;

export async function CreateTokenFromValues(values) {
  const { gisapProfileId, gisapHeadLine, usertoken, email } = values;

  let payload = {
    gisap_ProfileId: gisapProfileId,
    gisap_HeadLine: gisapHeadLine,
    email: email,
    username: email,
    usertoken: usertoken
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload)
  };

  const fetchUrl = `${apiUrl}/gisaptoken`;
  //console.log("fetching: ", fetchUrl);

  const response = await fetch(fetchUrl, requestOptions);
  const data = await handleResponse(response);
  //console.log("response data:", data);

  return data.gisapToken;

}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    const { gisapToken, error } = data;

    if (!gisapToken) {
      //notificationGraphQLError(error);
      const myError = new Error(error ? error: 'Unbekanntes Problem!');
      return Promise.reject(myError);
    }

    return data;

  });
}
