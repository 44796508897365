import React, { Component } from "react";
import { Popconfirm, Typography, message, Row, Col, Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import logo from "assets/logo_company-40px-8.png";
import Auth from "utils/Auth";

const appTitle = process.env.REACT_APP_MAIN_TITLE;
const { Text } = Typography;

const auth = new Auth();

class HeaderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: []
    };
  }

  handleConfirmedLogOut = () => {
    message.loading("Die aktuelle Sitzung wird beendet.", 2.5, () => {
      auth.logout();
    });
  };

  render() {
    const { selectedKeys } = this.state;
    const loggedIn = auth.isLoggedIn();
    const iconStyle = { fontSize: 16 };

    var menuItems = [];

    if (loggedIn) {
      menuItems.push(
        <Menu.Item key="navi:sst">
          <Link to="/sst">
            <Icon type="cloud-server" style={{ fontSize: 18 }} />
          </Link>
        </Menu.Item>
      );
      menuItems.push(
        <Menu.Item key="navi:sysinfo">
          <Link to="/sysinfo">
            <Icon type="bulb" style={{ fontSize: 18 }} />
          </Link>
        </Menu.Item>
      );
      menuItems.push(
        <Menu.Item key="user:logout">
          <Popconfirm
            title="Möchten Sie sich jetzt abmelden?"
            icon={<Icon type="question-circle-o" />}
            onConfirm={this.handleConfirmedLogOut}
            okText="OK"
            cancelText="Abbrechen"
            placement="topLeft"
          >
            <Icon type="poweroff" style={{ ...iconStyle }} />
          </Popconfirm>
        </Menu.Item>
      );
    } else {
      menuItems.push(
        <Menu.Item key="user:login">
          <Link to="/login">
            <Icon type="user" style={{ ...iconStyle }} />
            Anmelden
          </Link>
        </Menu.Item>
      );
    }

    return (
      <Row type="flex" justify="center">
        <Col span={12}>
          <Link to="/">
            <img
              style={{ verticalAlign: "middle", height: 38 }}
              src={logo}
              alt="HIT-SERVICES"
            />
          </Link>
          <Text strong style={{ marginLeft: 12 }}>
            {appTitle}
          </Text>
        </Col>
        <Col span={12}>
          <Menu
            mode="horizontal"
            selectedKeys={selectedKeys}
            style={{ float: "right" }}
          >
            {menuItems}
          </Menu>
        </Col>
      </Row>
    );
  }
}

export default HeaderContent;
