import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Container from "./container";
import Home from "./Home";
import ServerSideToken from './ServerSideToken';
import SysInfo from './SysInfo';
import Login from "./Login";
import NotFound from "components/NotFound";

import Auth from "utils/Auth";
const auth = new Auth();

// https://css-tricks.com/react-router-4/

export const makeMainRoutes = () => {
  return (
    <Container>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/sst" component={ServerSideToken} />
        <PrivateRoute path="/sysinfo" component={SysInfo} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Container>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const isAuthenticated = auth.isAuthenticated();
      const { location } = props;
      if (isAuthenticated) {
        return <Component auth={auth} {...props} />;
      } else {
        auth.setNextPath(location.pathname);
        return <Redirect to="/login" />;
      }
    }}
  />
);

export default makeMainRoutes;
