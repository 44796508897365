import React, { Component } from "react";
import { Card, Form, Icon, Input, Button } from "antd";
import Auth from "utils/Auth";
import QueueAnim from "rc-queue-anim";
import { notificationGraphQLError } from "utils/apolloUtils";

const auth = new Auth();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { username, password } = values;
        this.setState({
          isLoading: true
        }, () => {
          auth.login(username, password, this.handleLoginDone);
        });
      }
    });
  };

  handleLoginDone = () => {
    const lastError = auth.getLastError();
    if (lastError) notificationGraphQLError(lastError);
    this.setState({
      isLoading: false
    });
  };

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  render() {
    const { isLoading } = this.state;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    // Only show error after a field is touched.
    const usernameError =
      isFieldTouched("username") && getFieldError("username");
    const passwordError =
      isFieldTouched("password") && getFieldError("password");

    const iconStyle = {
      style: {
        color: "rgba(0,0,0,.5)"
      }
    };

    return (
      <div>
        <Card
          title="ANMELDUNG"
          bordered={true}
          style={{ textAlign: "center", width: 360 }}
        >
          <Form onSubmit={this.handleSubmit}>
            <QueueAnim delay={400}>
              <div key="1">
                <Form.Item
                  validateStatus={usernameError ? "error" : ""}
                  help={usernameError || ""}
                >
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        message: "Bitte gebe den Benutzer ein!"
                      }
                    ]
                  })(
                    <Input
                      prefix={<Icon type="user" {...iconStyle} />}
                      placeholder="Benutzer"
                    />
                  )}
                </Form.Item>
              </div>
              <div key="2">
                <Form.Item
                  validateStatus={passwordError ? "error" : ""}
                  help={passwordError || ""}
                >
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Bitte gebe das Passwort ein!"
                      }
                    ]
                  })(
                    <Input.Password
                      prefix={<Icon type="lock" {...iconStyle} />}
                      type="password"
                      placeholder="Passwort"
                    />
                  )}
                </Form.Item>
              </div>
              <div key="3">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={this.hasErrors(getFieldsError())}
                    loading={isLoading}
                  >
                    Log in
                  </Button>
                </Form.Item>
              </div>
            </QueueAnim>
          </Form>
        </Card>
      </div>
    );
  }
}

const WrappedLogin = Form.create({ name: "horizontal_login" })(Login);

export default WrappedLogin;
