import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

//const FORMAT_TIMEZONE = 'Etc/GMT';

const FORMAT_DATE = 'date';
const FORMAT_TIME = 'time';
const FORMAT_DATETIME = 'date-time' ;

const SmartDateTime = props => {

  const { value, format, relative = false } = props;

  var momentFormat;

  switch(format) {
    case FORMAT_DATE:
      momentFormat = 'DD.MM.YYYY';
      break;
    case FORMAT_TIME:
      momentFormat = 'HH:mm';
      break;
    case FORMAT_DATETIME:
      momentFormat = 'DD.MM.YYYY HH:mm';
      break;
    default:
      console.warn(`SmartDateTime: Missing format value. Using fallback format '${FORMAT_DATE}'!`);
      console.warn(`  Available values for format: '${FORMAT_DATE}', '${FORMAT_TIME}' or '${FORMAT_DATETIME}'.`);
      momentFormat = 'DD.MM.YYYY';
      break;
  };

  //console.log("DateTime:",moment(value).tz("Europe/London").format('DD.MM.YYYY HH:mm:ss'));
  const checkMoment = moment(value);
  var isRelative = false;

  if (relative){
    const triggerBeg = moment().add(-1,'day');
    const triggerEnd = moment().add(1,'day');
    isRelative = checkMoment.isBetween(triggerBeg, triggerEnd);
  }

  let result;

  if (isRelative) {
    result = checkMoment.fromNow();
  } else {
    result = checkMoment.format(momentFormat)
  }

  return (
    <span>{result}</span>
  );
};

SmartDateTime.propTypes = {
  value: PropTypes.any,
  format: PropTypes.oneOf([FORMAT_DATE,FORMAT_TIME,FORMAT_DATETIME]),
  relative: PropTypes.bool
};

export default SmartDateTime;
