import React from "react";
import { Tabs, Card, Alert, Descriptions, Button } from "antd";
import { loader } from "graphql.macro";
import { Query } from "react-apollo";
import NiceErrorNode from "components/NiceErrorNode";
import { SmartDateTime } from "components/Formatted";

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import sampleResponse from "./sampleResponse";
import sampleRequestBody from "./sampleRequestBody";

import Auth from "utils/Auth";
const auth = new Auth();

const { TabPane } = Tabs;
const helloServerQuery = loader("graphql/HelloServerQuery.graphql");

const apiUrl = process.env.REACT_APP_INIPROABS_GRAPHQL_API;

const SampleTokenRequest = props => {
  const userProfile = auth.getProfile();

  var sampleRequest = "{}";

  if (userProfile) {
    sampleRequest = sampleRequestBody.replace(
      "###UserEmail###",
      userProfile.email
    );
    sampleRequest = sampleRequest.replace("###UserName###", userProfile.email);
    sampleRequest = sampleRequest.replace(
      "###UserToken###",
      userProfile.usertoken
    );
  }

  return (
    <Card>
      <h3>Request:</h3>
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item label="URL">{apiUrl}/gisaptoken</Descriptions.Item>
        <Descriptions.Item label="METHOD">POST</Descriptions.Item>
        <Descriptions.Item label="HEADER">application/json</Descriptions.Item>
        <Descriptions.Item label="BODY">
          <SyntaxHighlighter language="json" style={docco}>
            {sampleRequest}
          </SyntaxHighlighter>
        </Descriptions.Item>
      </Descriptions>
      <div style={{ marginTop: 16 }}>
        <h3>Response:</h3>
      </div>
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item label="DATA">
          <SyntaxHighlighter language="json" style={docco}>
            {sampleResponse}
          </SyntaxHighlighter>
        </Descriptions.Item>
        <Descriptions.Item label="gisapToken">
          Json Web Token in der Form {"<Header>.<Payload>.<Signatur>"} zur
          INIPRO.ABS-Authentifizierung.
        </Descriptions.Item>
        <Descriptions.Item label="error">
          Enthält im Falle eines Fehlers eine Problembeschreibung
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

const HelloWorld = () => (
  <Query query={helloServerQuery}>
    {({ loading, error, data = {}, refetch }) => {
      const { helloServer = null } = data;
      const title = helloServer && `${helloServer.apiProductName}`;

      return (
        <Card
          loading={loading}
          title={title}
          //style={{ width: 600 }}
          extra={
            <span>
              <Button
                type={loading ? "disabled" : "default"}
                icon="reload"
                onClick={() => refetch()}
              />
            </span>
          }
        >
          {error && (
            <Alert
              message={<NiceErrorNode {...error} />}
              type="error"
              showIcon
            />
          )}
          {helloServer && (
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Version">
                {helloServer.apiVersion}
              </Descriptions.Item>
              <Descriptions.Item label="Endpoint">{apiUrl}</Descriptions.Item>
              <Descriptions.Item label="Mandant">
                {helloServer.mandantName}
              </Descriptions.Item>
              <Descriptions.Item label="DB-Host">
                {helloServer.mandantDatabase}
              </Descriptions.Item>
              <Descriptions.Item label="Host Time">
                <SmartDateTime
                  value={helloServer.hostTime}
                  format="date-time"
                />
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      );
    }}
  </Query>
);

const HomeLayout = props => {
  return (
    <div style={{ width: 800 }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Beispiel Token Request" key="1">
          <SampleTokenRequest />
        </TabPane>
        <TabPane tab="Host Informationen" key="2">
          <HelloWorld />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default HomeLayout;
