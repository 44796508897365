import history from "./history";
const NEXT_PATH_KEY = "next_path";
const ROOT_ROUTE = "/";

const AUTH_ACCESS_TOKEN = "auth_access_token";
const AUTH_ID_TOKEN = "auth_id_token";
const AUTH_EXPIRES_AT = "auth_expires_at";
const AUTH_LAST_ERROR = "auth_last_error";

let authHelper = require("utils/authHelper");
let jwt = require("jsonwebtoken");

export default class Auth {
  userValidated = false;

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  login(username, password, callBack) {
    this.clearSession();

    authHelper
      .loginWithPassword(username, password)
      .then(data => {
        const { loginWithPassword } = data;
        const { idToken } =  loginWithPassword;
        let authResult = authHelper.parseToken(idToken);
        this.handleAuthentication(authResult);
      })
      .catch(error => {
        localStorage.setItem(AUTH_LAST_ERROR, JSON.stringify(error));
        console.warn("loginWithPassword:", error);
      })
      .finally(() => {
        if (callBack) callBack();
      });
  }

  handleAuthentication(authResult) {
    localStorage.removeItem(AUTH_LAST_ERROR);

    if (authResult && authResult.accessToken && authResult.idToken) {
      this.setSession(authResult);
    } else {
      const lastErr = "Invariant authorization token!";
      localStorage.setItem(AUTH_LAST_ERROR, lastErr);
      console.error(lastErr);
    }
    // navigate to the next route
    history.replace(this.getNextPath());
    this.clearNextPath();
  }

  setSession(authResult) {
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem(AUTH_ACCESS_TOKEN, authResult.accessToken);
    localStorage.setItem(AUTH_ID_TOKEN, authResult.idToken);
    localStorage.setItem(AUTH_EXPIRES_AT, expiresAt);
  }

  clearSession() {
    // Clear access token and ID token from local storage
    this.userValidated = false;
    localStorage.removeItem(AUTH_ACCESS_TOKEN);
    localStorage.removeItem(AUTH_ID_TOKEN);
    localStorage.removeItem(AUTH_EXPIRES_AT);
    localStorage.removeItem(AUTH_LAST_ERROR);
  }

  getLastError() {
    let result = JSON.parse(localStorage.getItem(AUTH_LAST_ERROR));
    return result;
  }

  logout() {
    this.clearSession();
    this.clearNextPath();
    history.replace(ROOT_ROUTE);
  }

  logoutWithoutRedirect() {
    this.clearSession();
    this.clearNextPath();
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    var result;
    try {
      let expiresAt = JSON.parse(localStorage.getItem(AUTH_EXPIRES_AT) || "");
      //console.log("Token expiresAt:", new Date(expiresAt) );
      result = new Date().getTime() < expiresAt;
    } catch (error) {
      result = false;
    }
    return result;
  }

  isLoggedIn() {
    const idToken = this.getIdToken();
    return idToken && this.isAuthenticated();
  }

  isValidated() {
    if (!this.isLoggedIn) {
      return false;
    }
    return this.userValidated;
  }

  getProfile() {
    const idToken = this.getIdToken();
    var decoded = jwt.decode(idToken);
    let profile = {
      email: decoded.email,
      emailVerified: decoded.email_verified,
      usertoken: decoded.usertoken,
    };
    return profile;
  }

  getAccessToken() {
    const accessToken = localStorage.getItem(AUTH_ACCESS_TOKEN);
    if (!accessToken) {
      throw new Error("No access token found");
    }
    return accessToken;
  }

  getIdToken() {
    return localStorage.getItem(AUTH_ID_TOKEN);
  }

  setNextPath(nextPath) {
    localStorage.setItem(NEXT_PATH_KEY, nextPath);
  }

  getNextPath() {
    return localStorage.getItem(NEXT_PATH_KEY) || ROOT_ROUTE;
  }

  clearNextPath() {
    localStorage.removeItem(NEXT_PATH_KEY);
  }
}
