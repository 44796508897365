import { loader } from "graphql.macro";
import { loginClient } from "utils/create-apollo-client";

let jwt = require("jsonwebtoken");
const loginQuery = loader("graphql/LoginWithPasswordQuery.graphql");

export async function loginWithPassword(username, password) {
  //console.log("ApolloClient:", loginClient);
  const { data } = await loginClient.query({
    query: loginQuery,
    variables: {
      username: username,
      userpass: password
    }
  });
  return data;
}

export function parseToken(token) {
  var decoded = jwt.decode(token);
  let expiresIn = decoded.exp - decoded.iat;

  let authResult = {
    accessToken: decoded.usertoken,
    idToken: token,
    expiresIn: expiresIn
  };

  return authResult;
}
