import React, { Component } from "react";
import { Card, Spin, Button, notification } from "antd";
import GisapTokenEditForm from "./GisapTokenEditForm";

import Auth from "utils/Auth";
const auth = new Auth();

let taasLib = require("utils/taasFactory");

const appTitle = process.env.REACT_APP_MAIN_TITLE;
const gisapDestinHost = process.env.REACT_APP_GISAP_DESTINATION_HOST;

function OpenNewWindow(targetUrl) {
  const winHeight = 680;
  const winWidth = 1024;
  let left = (window.screen.width - winWidth) / 2;
  let top = (window.screen.height - winHeight) / 4;
  window.open(
    targetUrl,
    "INIPRO.ABS",
    "toolbar=no, location=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=" +
      winWidth +
      ", height=" +
      winHeight +
      ", top=" +
      top +
      ", left=" +
      left
  );
}

const ButtonJwtPreview = ({ token, iniproabsProfileId }) => {

  if (!token) return null;

  let targetUrl = `${gisapDestinHost}/pp/customer/${iniproabsProfileId}/gisapconnect/#gisaptoken=${token}`;
  let jwtIoTestUrl = `https://jwt.ms/#id_token=${token}`;

  console.log("targetUrl:", targetUrl);

  return (
    <div>
      <Button href={jwtIoTestUrl} target="_blank" rel="noopener noreferrer">
        Preview on jwt.ms
      </Button>
      <Button
        style={{ marginLeft: 10 }}
        href={targetUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        INIPRO.ABS: New Tab
      </Button>
      <Button
        style={{ marginLeft: 10 }}
        onClick={() => {
          OpenNewWindow(targetUrl);
        }}
      >
        INIPRO.ABS: New Window
      </Button>
    </div>
  );
};

class GisapTokenEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: true,
      isLoading: false,
      gisapToken: null,
      userProfile: null,
      iniproabsProfileId: null
    };
  }

  componentWillMount() {
    const loggedIn = auth.isLoggedIn();
    if (loggedIn) {
      const userProfile = auth.getProfile();
      this.setState({ userProfile });
    }
  }

  handleSubmit = () => {
    const form = this.form;

    form.validateFields((err, values) => {
      try {
        // local form validation errors...
        if (err) {
          return;
        }

        // very important: prevent double clicked submit-events!
        if (!this.state.canSubmit) return;

        this.setState({
          canSubmit: false,
          gisapToken: null,
          isLoading: true
        });

        //console.log("Values:", values);

        var tokenCreator = () => taasLib.CreateTokenFromValues(values);

        tokenCreator()
          .then(token => {
            this.setState(
              {
                gisapToken: token,
                iniproabsProfileId: values.iniproabsProfileId,
                isLoading: false,
                canSubmit: true
              },
              () => {
                notification.info({
                  message: appTitle,
                  description: "Token erfolgreich erstellt."
                });
              }
            );
          })
          .catch(error => {
            notification.error({
              message: appTitle,
              description: error.message,
              duration: 5,
              onClose: () =>
                this.setState({ canSubmit: true, isLoading: false })
            });
            console.error(error);
          });
      } catch (error) {
        notification.error({
          message: appTitle,
          description: error.message,
          duration: 5,
          onClose: () => this.setState({ canSubmit: true, isLoading: false })
        });
        console.error(error);
      }
    });
  };

  saveFormRef = form => {
    this.form = form;
  };

  render() {
    const { gisapToken, iniproabsProfileId, canSubmit, isLoading, userProfile } = this.state;

    //console.log(gisapToken);
    const props = {
      payload: {
        gisapProfileId: "051MbJV67kcLZlftZl}gPG",
        gisapHeadLine: "SAP: Zeitreihen Upload",
        usertoken: userProfile && userProfile.usertoken,
        email: userProfile && userProfile.email
      },
      buttonTitle: "Token erstellen",
      canSubmit: canSubmit,
      onSubmit: this.handleSubmit,
      ref: this.saveFormRef
    };

    return (
      <Card title="Token Payloads" style={{ width: 680, minHeight: 270 }}>
        <Spin spinning={isLoading}>
          <GisapTokenEditForm {...props} />
        </Spin>
        {gisapToken && <ButtonJwtPreview token={gisapToken} iniproabsProfileId={iniproabsProfileId} />}
      </Card>
    );
  }
}

export default GisapTokenEdit;
