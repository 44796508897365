import React from "react";
import { notification } from "antd";

/*
 * Extract GraphQLError messages
 */
export function convertGraphQLError(error) {
  let result = {
    errorCode: null,
    redirectLink: null,
    messages: []
  };

  if (!error) return result;

  const { message, networkError, graphQLErrors } = error;

  // 1. Möglichkeit: GraphQL-Fehler ist aufgetreten
  if (graphQLErrors && graphQLErrors.length) {
    error.graphQLErrors.forEach(graphQLError => {
      // Ziel ist es, den optionalen Fehlercode ("#Ennn:") zufinden
      // und die letzte Standardmeldung bei GraphQL-Erros zu eliminieren:
      // -------------------------------------------------------
      // Original:
      // [0]: "#E022: Mandant 'DEMO-3.0' Offline. Das System wird zur Zeit gewartet!"
      // [1]: "Error trying to resolve katalogGesamt."
      // -------------------------------------------------------
      // Konvertiert:
      // [0]: "Mandant 'DEMO-3.0' Offline. Das System wird zur Zeit gewartet!"

      const origin = graphQLError.message;

      if (origin.indexOf("Error trying to resolve") === -1) {
        let foundErr = origin.match(/(#(E\d\d\d):)+/g);
        if (foundErr) {
          result.errorCode = foundErr[0].replace(/(#|:)+/g, "");
        }
        result.messages.push(origin.replace(foundErr, "").trim());
      }
    });
  }

  // 2. Möglichkeit: Netzwerkprobleme
  if (networkError) {
    if (networkError.message) {
      result.messages.push(`Network: ${networkError.message}`);
    } else if (networkError.result && networkError.result.exceptionMessage) {
      result.messages.push(`Network: ${networkError.result.exceptionMessage}`);
    } else if (message) {
      result.messages.push(message);
    }
  }

  result.redirectLink = null;

  return result;
}

/*
 * Displays GraphQLError messages
 */
export function notificationGraphQLError(error) {
  console.log("Found errors:", error);
  const costumErrors = convertGraphQLError(error);
  const { messages = [] } = costumErrors;
  let childs = [];

  if (messages.length !== 0) {
    messages.forEach(message => {
      const nodes = message
        .split("\n")
        .map((text, index) => <div key={`error-${index}`}>{text}</div>);
      childs.push(nodes);
    });
  }

  if (childs.length === 0) {
    childs.push(
      <div key="unknown-err">
        <p key="unknown-err-1">
          {error === "string"
            ? error
            : "Es liegt keine Beschreibung des Problems vor."}
        </p>
      </div>
    );
  }

  notification.error({
    message: "Problem aufgetreten",
    duration: 6,
    description: <div id="graphqlErrors">{childs}</div>
  });
}
