// Migration from 1.0 to 2.0
//https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-http#upgrading-from-apollo-fetch--apollo-client

import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import Auth from "./Auth";

const hostUrl = process.env.REACT_APP_INIPROABS_GRAPHQL_API;
const httpLink = createHttpLink({ uri: hostUrl });
const loginHttpLink = createHttpLink({ uri: `${hostUrl}/login` });

const middlewareLink = new ApolloLink((operation, forward) => {
  const auth = new Auth();
  operation.setContext({
    headers: {
      authorization: auth.getIdToken() || null
    }
  });
  // https://github.com/apollostack/apollo-client/issues/881
  operation.variables = JSON.stringify(operation.variables);
  return forward(operation);
});

const loginMiddlewareLink = new ApolloLink((operation, forward) => {
  // https://github.com/apollostack/apollo-client/issues/881
  operation.variables = JSON.stringify(operation.variables);
  return forward(operation);
});

export const loginClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: loginMiddlewareLink.concat(loginHttpLink)
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: middlewareLink.concat(httpLink)
});

export default client;
