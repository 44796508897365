import React from 'react';
import { convertGraphQLError } from 'utils/apolloUtils';

function NiceErrorNode(props) {

  //console.log ('NiceErrorNode');

  let childs = [];

  const costumErrors = convertGraphQLError(props);
  const { messages = [] } = costumErrors;

  if (messages.length !== 0) {
    messages.forEach((message) => {
      const nodes = message.split('\n').map((text, index) => <p key={`error-${index}`}>{text}</p>)
      childs.push(nodes);
    });
  }

  if (childs.length === 0){
    childs.push(
      <div key="unknown-err">
        <p key="unknown-err-1">Ein unbekanntes Problem ist aufgetreten.</p>
        <p key="unknown-err-2">Bitte wenden Sie sich an den Administrator, um weitere Hilfe zu erhalten.
        </p>
      </div>
    );
  }

  return (
    <div id="graphqlErrors">
      {childs}
    </div>
  )
}

/*
NiceErrorNode.propTypes = {
  graphQLErrors: PropTypes.array,
  networkError: PropTypes.object,
};
*/

export default NiceErrorNode;
