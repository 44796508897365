import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Alert, Button } from "antd";

const inputErrMsg = "Dieser Wert muss noch angegeben werden.";

class GisapTokenEditForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    canSubmit: PropTypes.bool,
    buttonTitle: PropTypes.string,
    payload: PropTypes.shape({
      gisapProfileId: PropTypes.string.isRequired,
      gisapHeadLine: PropTypes.string.isRequired,
      usertoken: PropTypes.string,
      email: PropTypes.string
    }).isRequired
  };

  handleSumbit = () => {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit();
  };

  render() {
    const { payload, canSubmit = false, buttonTitle = "OK" } = this.props;

    if (!payload) {
      return (
        <Alert message="Prop `payload` is marked as required!" type="error" />
      );
    }

    const { getFieldDecorator } = this.props.form;
    const leftSpan = 8;
    const colSpan = 16;

    const formItemLayout = {
      style: {
        marginBottom: 10
      },
      colon: false,
      labelCol: { span: leftSpan },
      wrapperCol: { span: colSpan },
      className: "form-left-label"
    };

    const layoutProps = {
      hideRequiredMark: true
    };

    return (
      <Form {...layoutProps}>
        <Form.Item label="GiSap ProfileId" {...formItemLayout}>
          {getFieldDecorator("gisapProfileId", {
            initialValue: payload.gisapProfileId,
            rules: [{ required: true, message: inputErrMsg }]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="GiSap Headline" {...formItemLayout}>
          {getFieldDecorator("gisapHeadLine", {
            initialValue: payload.gisapHeadLine,
            rules: [{ required: true, message: inputErrMsg }]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="User Email" {...formItemLayout}>
          {getFieldDecorator("email", {
            initialValue: payload.email,
            rules: [{ required: true, message: inputErrMsg }]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="User Token" {...formItemLayout}>
          {getFieldDecorator("usertoken", {
            initialValue: payload.usertoken,
            rules: [{ required: true, message: inputErrMsg }]
          })(<Input.Password />)}
        </Form.Item>

        <Form.Item label="INIPRO.ABS Profile-ID" {...formItemLayout}>
        {getFieldDecorator("iniproabsProfileId", {
          initialValue: 26,
          rules: [{ required: true, message: inputErrMsg }]
        })(<Input />)}
      </Form.Item>

        <Form.Item wrapperCol={{ span: leftSpan, offset: leftSpan }}>
          <Button
            type="primary"
            disabled={!canSubmit}
            onClick={this.handleSumbit}
          >
            {buttonTitle}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const withForm = Form.create({ name: "token_inputs" })(GisapTokenEditForm);

export default withForm;
